@use "../utils/colors";
@use "../utils/media-queries";

.button-responsive {
    display: block;
    width: 100%;
}

.button-responsive + .button-responsive {
    margin-top: 10px;
}

.button-blue.mdl-button:not(:disabled) {
    background-color: colors.$blue;
    color: colors.$white;

    &:hover,
    &:focus,
    &:focus:not(:active) {
        background-color: colors.$blue-hover;
    }
}
.button-green.mdl-button:not(:disabled) {
    background-color: colors.$green;
    color: colors.$white;

    &:hover,
    &:focus,
    &:focus:not(:active) {
        background-color: colors.$green-hover;
    }
}

.button-red.mdl-button {
    background-color: colors.$red;
    color: colors.$white;

    &:hover,
    &:focus,
    &:focus:not(:active) {
        background-color: colors.$red-hover;
    }
}

@include media-queries.bp-medium {
    .button-responsive {
        display: inline-block;
        width: auto;
    }
    .button-responsive + .button-responsive {
        margin-left: 10px;
        margin-top: 0;
    }
}
