.loader {
    margin: 0 auto;
    max-width: 200px;
    text-align: center;
    width: 100%;
}

.loader__label {
    display: block;
    margin-bottom: 16px;
}

.loader__line,
.loader__line:before {
    height: 3px;
    width: 100%;
    margin: 0;
}

.loader__line {
    background-color: $light-blue;
    display: flex;
}

.loader__line:before {
    animation: running-progress 1s cubic-bezier(0.4, 0.3, 0.4, 0.2) infinite;
    background-color: $blue;
    content: '';
}

@keyframes running-progress {
    0% { margin-left: 0px; margin-right: 100%; }
    50% { margin-left: 25%; margin-right: 0%; }
    100% { margin-left: 100%; margin-right: 0; }
}
