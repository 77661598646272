@use "../utils/colors";
@use "../utils/media-queries";

.nav-bar-header__row {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.nav-bar-drawer.is-active {
    transform: translateX(0);
}

.nav-bar-drawer__header {
    border-bottom: 1px solid rgba(255,255,255, 0.12);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 16px;
    height: 151px;
}

.nav-bar-drawer__header-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    text-decoration: none;
}

.nav-bar-drawer__header-button i {
    vertical-align: middle;
}

.nav-bar-drawer__avatar {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    > span {
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
}

.nav-bar-drawer__navigation .mdl-navigation__link {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    font-weight: 500;

    &:hover,
    &:hover > i,
    &.active,
    &.active> i {
        color: colors.$white !important;
    }

    &:hover,
    &.active {
        background-color: rgba(colors.$white, 0.08) !important;
    }
}

.mdl-navigation__link .material-icons {
    font-size: 24px;
    color: rgba(colors.$white, 0.56);
    margin-right: 20px;
}

.nav-bar-drawer__header-home {
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;

    &:hover,
    &:focus {
        color: #757575;

        > svg {
            fill: #757575;
        }
    }
}

.nav-bar-drawer__header-home > svg {
    fill: white;
    height: 40px;
    width: 40px;
    margin-right: 10px;
}

.nav-bar-drawer__header-home > h1 {
    font-size: 40px;
}

.disabled {
    color: rgb(170, 170, 170);
}

@include media-queries.bp-large {
    .mdl-layout__header-row.nav-bar-header__row {
        margin-left: 240px;
    }

    .mdl-layout__obfuscator {
        display: none;
    }
}
