.text-bold {
    font-weight: bold;
}

.text-link:hover,
.text-link:focus {
    opacity: 0.75;
}

/** Needs to have a width set, as well as not be inline to work **/
.text-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
