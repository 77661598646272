@use "../utils/colors";
@use "../utils/media-queries";

.modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 11000;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

.modal-container__modal {
    box-sizing: border-box;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    max-width: 420px;
    pointer-events: all;
}

.modal-title {
    font-size: 24px;
    margin-bottom: 30px;
}

.edit-modal {
    padding: 40px 24px;
    background-color: colors.$white;
}

.edit-modal__title {
    margin-top: 0;
    margin-bottom: 8px;
}

.edit-modal__buttons {
    text-align: right;
    padding-top: 16px;
}

.mdl-textfield.edit-modal__field {
    width: 100%;
    padding: 16px 0;
}

.edit-modal__input {
    outline: none;
}

.edit-modal__checkbox-container {
    display: block;
    margin-top: 8px;
}

.edit-modal__checkbox-container.disabled {
    color: rgb(170, 170, 170);
}

.edit-modal__checkbox {
    margin-left: 0;
    margin-right: 8px;
}

.modal-drag-drop {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    aspect-ratio: 2/1;
    width: 100%;
    background-color: lightgray;
}

.modal-drag-drop__label {
    cursor: pointer;
    text-align: center;
}

.modal-drag-drop__input {
    display: none;
}

@include media-queries.bp-medium {
    .modal-container__modal {
        height: auto;
    }
}
