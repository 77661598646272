.app-loader {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
}

.app__content {
    max-width: 1080px;
    padding: 48px 0;
}

.app__content-wrapper {
    padding: 0 24px;
}

@include bp-large {
    .app__content-wrapper {
        padding: 0 96px;
    }
}
