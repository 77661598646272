.unauth-modal {
    padding: 16px 24px 32px;
    margin: 20vh auto;
    max-width: 420px;
}

.unauth-modal__body {
    display: flex;
    padding: 14px 20px 16px;
    margin-top: 20px;
    background-color: rgba(232, 23, 23, 0.08);
}

.unauth-modal__body > .material-icons {
    padding: 4px 20px 0 0;
}

.unauth-modal__contact {
    margin-top: 12px;
}

.auth-modal {
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba($black, 0.24), 0 0 2px 0 rgba($black, 0.12);
    padding: 32px 40px;
}

.auth-modal__header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth-modal__header > svg {
    height: 40px;
    width: 40px;
    margin-right: 10px;
}

.auth-modal__header > h1 {
    font-size: 40px;
}

.auth-modal__login {
    text-align: center;
    margin-top: 32px;
}
