.customer-search {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding: 0 20px;
    border: 1px solid #cccccc;
    border-radius: 100px;
}

.customer-search > i {
    color: rgb(102,102,102);
    margin-right: 10px;
}

.customer-search__form {
    width: 100%;
}

.customer-search__input-container:focus-within {
    position: relative;
}

.customer-search__input-container:focus-within::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: #4252AF;
}

.customer-search__text-field-container.mdl-textfield {
    max-width: 300px;
    width: 100%;
}

.customer-search__input.mdl-textfield__input:focus {
    position: relative;
    outline: none;
}

.customer-search__clear {
    margin-left: auto;
    margin-right: 20px;
    cursor: pointer;
}
