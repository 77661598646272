@keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.clipboard-button {
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    opacity: 0;
}

.clipboard-button:active > span {
    opacity: 0.2;
}

.clipboard-button:hover {
    opacity: 1;
}

.clipboard-button > span.material-icons {
    font-size: 14px;
    vertical-align: text-top;
}

.clipboard-button--loading {
    opacity: 1;
    color: rgb(170, 170, 170);

    > span.material-icons {
        animation: spin 0.8s infinite linear;
    }
}

.clipboard-button-text:hover + .clipboard-button {
    opacity: 1;
}
