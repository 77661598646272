@use "../utils/colors";

.card {
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(colors.$black, 0.24), 0 0 2px 0 rgba(colors.$black, 0.12);
    margin-bottom: 8px;
    padding: 24px 16px;
}

.card:last-child {
    margin-bottom: 0;
}

.card--payout {
    padding: 0;
}

.card__header {
    padding: 16px;
}

.card__footer {
    padding: 20px 16px 24px;
}

.card__footer--unconfirmed {
    background-color: rgba(153,153,153, 0.08);
}

.card__footer--confirmed {
  background-color: rgba(126, 211, 33, 0.04);
}

.card__footer-title {
    font-size: 16px;
    line-height: 24px;
}

.card__footer-body {
    font-size: 14px;
    line-height: 24px;
}

.card__footer-note {
    display: block;
    font-size: 14px;
    line-height: 24px;
    margin-top: 6px;
    max-width: 680px;
}

.card__confirmation {
    align-items: center;
    display: flex;
    padding: 0 16px;
}

.card__account-header {
    display: flex;
    justify-content: space-between;
}

.card__title {
    color: rgba(colors.$black, 0.87);
    font-family: 'Roboto', 'Helvetica', sans-serif;
    font-size: 24px;
    line-height: 1.33;
    margin: 0;
    margin-bottom: 16px;
    padding-bottom: 8px;
}

.card__results {
    color: rgba(colors.$black, 0.87);
    font-family: 'Roboto', 'Helvetica', sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
}

.card__array {
    list-style: none;
    margin: 0;
    padding: unset;
    flex-direction: column;
}

.card__item-group-key {
    display: inline-block;
    min-width: 180px;
}

.card__item-group-key--devices {
    display: inline-block;
    min-width: 220px;
}

.card__item-group-value--notifications-id {
    display: inline-block;
    width: 80px;
    vertical-align: middle;
}

a.card__item-group-value:hover {
    opacity: 0.8;
}

.card__item-group-value--access-link {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.card__user-uid {
    margin-bottom: 12px;
}

.card__button-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.card__edit-button,
.card__delete-button {
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    font-family: 'Roboto', 'Helvetica', sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
}

.card__edit-button:hover,
.card__delete-button:hover {
    opacity: 0.8;
}

.card__edit-button > span,
.card__delete-button > span {
    vertical-align: text-top;
    font-size: 18px;
}

.card__delete-button {
    color: colors.$red;
}

.card__inline-edit {
    display: flex;
    align-items: flex-end;
    max-width: 100%;
    margin-bottom: 18px;

    input {
        height: 28px;
    }

    button {
        margin-left: 10px;
        flex-shrink: 0;
    }
}

.card__user-uid--devices:hover + button.clipboard-button,
.card__user-uid:hover + button.clipboard-button,
.card__item-group-value--email:hover + button.clipboard-button,
.card__item-group-value--notifications-id:hover + button.clipboard-button,
.card__item-group-value--device-id:hover + button.clipboard-button,
.card__item-group-value--access-link:hover + button.clipboard-button,
.card__user-email-list--devices > li:hover > button.clipboard-button,
.card__user-email-list > li:hover > button.clipboard-button,
.card__related-accounts-list > li:hover > button.clipboard-button {
    opacity: 1;
}
