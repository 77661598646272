$mq-medium: "(min-width: 768px)";
$mq-large: "(min-width: 1025px)";

@mixin bp-medium {
    @media #{$mq-medium} {
        @content;
    }
}

@mixin bp-large {
    @media #{$mq-large} {
        @content;
    }
}
