.button-responsive {
    display: block;
    width: 100%;
}

.button-responsive + .button-responsive {
    margin-top: 10px;
}

.button-blue.mdl-button:not(:disabled) {
    background-color: $blue;
    color: $white;

    &:hover,
    &:focus,
    &:focus:not(:active) {
        background-color: $blue-hover;
    }
}
.button-green.mdl-button:not(:disabled) {
    background-color: $green;
    color: $white;

    &:hover,
    &:focus,
    &:focus:not(:active) {
        background-color: $green-hover;
    }
}

.button-red.mdl-button {
    background-color: $red;
    color: $white;

    &:hover,
    &:focus,
    &:focus:not(:active) {
        background-color: $red-hover;
    }
}

@include bp-medium {
    .button-responsive {
        display: inline-block;
        width: auto;
    }
    .button-responsive + .button-responsive {
        margin-left: 10px;
        margin-top: 0;
    }
}
