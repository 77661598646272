@use "../utils/colors";

.full-page-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    background-color: rgba(colors.$black, 0.8);
}
